export const PAGE_SELECTOR_OPTIONS = [
    {value: 50, label: '50 Trades'},
    {value: 100, label: '100 Trades'},
    {value: 500, label: '500 Trades'},
    {value: 1000, label: '1000 Trades'},
];

export const PAGE_SELECTOR_OPTIONS_SETTLEMENT = [
    {value: 50, label: '50 Settlements'},
    {value: 100, label: '100 Settlements'},
    {value: 500, label: '500 Settlements'},
    {value: 1000, label: '1000 Settlements'},
];

export const PAGE_SELECTOR_OPTIONS_EXCEPTIONS = [
    {value: 50, label: '50 Exceptions'},
    {value: 100, label: '100 Exceptions'},
    {value: 500, label: '500 Exceptions'},
    {value: 1000, label: '1000 Exceptions'},
];

export const CUSTOM_PREFERENCE_OPTIONS = [{value: 'table', label: 'Table'}];

//[TO-DO:] Remove "placeholder" key when Polaris v3 migration is complete for all components.
export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: 'Filter by property or value',
    filteringPlaceholder: 'Filter by property or value',
    operationAndText: 'and',
    operationNotAndText: 'and not',
    operatorContainsText: "Contains",
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: "Does not Equal",
    operationOrText: 'or',
    operationNotOrText: 'or not',
    clearFiltersText: 'Clear Filters',
    editTokenHeader: "Edit Filter",
    groupPropertiesText: 'Properties',
    groupValuesText: 'Values',
    filteringEmpty: 'No results found',
    filteringLabel: 'Filter Trades',
    filteringStatusText: 'Loading',
    applyActionText: 'Apply',
    cancelActionText: 'Cancel',
    removeTokenButtonAriaLabel: () => 'Remove token',
    enteredTextLabel: (text) => `Use: "${text}"`
};

export const PAGE_SIZE = 50;

export const SORT_COLUMN_OPTIONS = ["System ID", "Trade ID"];

// Trade Module
export const VISIBLE_COLUMN_OPTIONS_OPEN_TRADE = ["Status", 'File Attached', "Currency Pair", "Value Date", "Buy/Sell", "Notional", "Notional CCY", "Source Account", "Beneficiary", "Account",
    "Record Status", "USD Notional", "Trade ID", "TT ID", "Trade Code", "Checked Out User", "Trade Type", "Initiator ID", "Approver", 'Approval Date', "Approval ID", "Creation_Date", "Update_Date", "Updated_By",
    "Created_By", "CF Action", "MarketType", "System ID", "Exchange Rate", "Exchange Date", "MISYS_Beneficiary", "Trade Details", "Batch ID", "Trade System", "Checked Out Date", "Export Date", "Export File", "Execution Date",
    "Rejection Date", "Duplicate Flag", "Duplicate Trade System Ids", "Definition"];

export const HIDDEN_OPTIONS_TRADE = [
    "Created_By", "CF Action", "MarketType", "System ID", "Exchange Rate", "Exchange Date", "MISYS_Beneficiary", "Trade Details", "Batch ID", "Trade System", "Checked Out Date", "Export Date",
    "Export File", "Execution Date", "Rejection Date", "Duplicate Flag", "Duplicate Trade System Ids"];

export const VISIBLE_COLUMN_OPTIONS_HISTORICAL = ["Status", 'File Attached', "Currency Pair", "Value Date", "Buy/Sell", "Notional", "Notional CCY", "Source Account", "Beneficiary", "Account",
    "USD Notional", "Trade ID", "TT ID", "Trade Code", "Checked Out User", "Trade Type", "Initiator ID", "Approver", 'Approval Date', "Approval ID", "Creation_Date", "Update_Date", "Updated_By",
    "Created_By", "CF Action", "MarketType", "System ID", "Exchange Rate", "Exchange Date", "MISYS_Beneficiary", "Trade Details", "Batch ID", "Trade System", "Checked Out Date", "Export Date", "Export File", "Execution Date",
    "Rejection Date", "Duplicate Flag", "Duplicate Trade System Ids"];

// Payment Module
export const VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT = ['Settlement ID', 'File Attached', 'Sending Region', 'File Type', 'Payment Type', 'Settlement Cycle', 'Status', 'Nature of Balance',
    'Sending Co', 'Sending Co Name', 'Sending Currency', 'Sending Account', 'Sending Branch', 'Sending Account Type', 'Sending Amount', 'Sending Account Balance', 'Receiving Region',
    'Receiving Co', 'Receiving Co Name', 'Receiving Currency', 'Receiving Account', 'Receiving Branch', 'Receiving Account Type', 'Receiving Amount', 'Receiving Account Balance',
    'Settlement USD Equiv', 'Target Amount', 'Target Currency', 'Liquidity Check', 'IC out 50%',
    'Latest Comment', 'OCM Mapping', 'Value Date', 'TT ID',
    'Upload_Date', 'Upload_File', 'Upload_User', 'Created_By', 'Creation_Date',  'Update_By', 'Update_Date', 'Approved_By', 'Approval_Date', 'Rejected_By', 'Rejection_Date', 'Exported_By', 'Export_Date'];

export const VISIBLE_COLUMN_OPTIONS_APPROVED_PAYMENT = ['Settlement ID', 'File Attached', 'Sending Region', 'File Type', 'Payment Type', 'Settlement Cycle', 'Status', 'Nature of Balance',
    'Sending Co', 'Sending Co Name', 'Sending Currency', 'Sending Account', 'Sending Branch', 'Sending Amount', 'Receiving Region',
    'Receiving Co', 'Receiving Co Name', 'Receiving Currency', 'Receiving Account', 'Receiving Branch', 'Receiving Amount',
    'Settlement USD Equiv', 'Target Amount', 'Target Currency',
    'Latest Comment', 'OCM Mapping', 'Value Date', 'TT ID',
    'Upload_Date', 'Upload_File', 'Upload_User', 'Created_By', 'Creation_Date', 'Update_By', 'Update_Date', 'Approved_By', 'Approval_Date', 'Rejected_By', 'Rejection_Date', 'Exported_By', 'Export_Date'];

export const VISIBLE_COLUMN_OPTIONS_HISTORICAL_PAYMENT = ['Settlement ID', 'File Attached', 'Sending Region', 'File Type', 'Payment Type', 'Settlement Cycle', 'Status', 'Nature of Balance',
    'Sending Co', 'Sending Co Name', 'Sending Currency', 'Sending Account', 'Sending Branch', 'Sending Amount', 'Receiving Region',
    'Receiving Co', 'Receiving Co Name', 'Receiving Currency', 'Receiving Account', 'Receiving Branch', 'Receiving Amount',
    'Settlement USD Equiv', 'Target Amount', 'Target Currency',
    'Latest Comment', 'OCM Mapping', 'Value Date', 'TT ID',
    'Upload_Date', 'Upload_File', 'Upload_User', 'Created_By', 'Creation_Date', 'Update_By', 'Update_Date', 'Approved_By', 'Approval_Date', 'Rejected_By', 'Rejection_Date', 'Exported_By', 'Export_Date'];

export const HIDDEN_OPTIONS_PENDING_SETTLEMENTS = ['Upload_Date', 'Upload_File', 'Upload_User', 'Created_By', 'Creation_Date',  'Update_By', 'Update_Date', 'Approved_By', 'Approval_Date', 'Rejected_By', 'Rejection_Date', 'Exported_By', 'Export_Date'];

export const HIDDEN_OPTIONS_PENDING_LIQUIDITY = ['Status', 'Upload_Date', 'Upload_File', 'Upload_User', 'Created_By', 'Creation_Date',  'Update_By', 'Update_Date', 'Approved_By', 'Approval_Date', 'Rejected_By', 'Rejection_Date', 'Exported_By', 'Export_Date'];

export const DATE_COLUMN_OPTIONS_QUERYTRADE = ["Checked Out Date", "Export Date", "Creation_Date", "Update_Date"];

export const VISIBLE_COLUMN_OPTIONS_EXCEPTION = ['Exception Status', 'File Attached', 'Inquiry Date', 'Exception Owner', 'Exception Category', 'Exception Sub-category', 'Trade ID', 'Counterparty', 'Trade Date', 'Value Date',
    'Sold CCY', 'Sold Amount', 'Bought CCY', 'Bought Amount', 'Source Account Code', 'MISYS ID', 'Beneficiary Account Code', 'Related TT', 'Resolution Date', 'Exception Cost $', 'Root Cause', 'Latest Comment', 'Updated_By', 'Update_Date'];
    
export const HIDDEN_COLUMN_OPTIONS_EXCEPTION = ['Trade Category', 'Trader', 'USD Equivalent', 'Spot Rate', 'CCY Pair', 'Target CCY', 'Reference', 'Trouble Ticket', 'Trade Origin',
    'Source Account Company Code', 'Source Account Name', 'Source Account Bank Branch', 'Source Account Number', 'Debit Date', 'Debit Description',
    'Beneficiary Account Name', 'Beneficiary BIC', 'Beneficiary Intermediary BIC', 'Beneficiary Account Number', 'MISYS ID Instructed', 'MISYS ID Reconciled', 'Credit Date', 'Credit Description', 'System ID'];

export const VISIBLE_COLUMN_OPTIONS_ALL_TRADES = ['Trade ID', 'File Attached', 'Trade Date', 'Value Date', 'Counterparty', 'Trade Category', 'Trader', 'Sold CCY', 'Sold Amount', 'Bought CCY', 'Bought Amount', 'USD Equivalent', 'Spot Rate', 'CCY Pair',
    'Target CCY', 'Reference', 'Trouble Ticket', 'Trade Origin', 'Source Account Code', 'Source Account Company Code', 'Source Account Name', 'Source Account Bank Branch', 'Source Account Number', 'Beneficiary Account Code', 'MISYS ID',
    'Beneficiary Account Name', 'Beneficiary BIC', 'Beneficiary Intermediary BIC', 'Beneficiary Account Number'];

export const HIDDEN_COLUMN_OPTIONS_ALL_TRADES = ['System ID', 'Updated_By', 'Update_Date', 'Debit Date', 'Debit Description'];

export const ALL_TRADES_ATTRIBUTES_FOR_DOWNLOAD = ['Trade ID', 'File Attached', 'Trade Date', 'Value Date', 'Counterparty', 'Trade Category', 'Trader', 'Sold CCY', 'Sold Amount', 'Bought CCY', 'Bought Amount', 'USD Equivalent', 'Spot Rate', 'CCY Pair',
    'Target CCY', 'Reference', 'Trouble Ticket', 'Trade Origin', 'Source Account Code', 'Source Account Company Code', 'Source Account Name', 'Source Account Bank Branch', 'Source Account Number', 'Debit Date', 'Debit Description',
    'Beneficiary Account Code', 'MISYS ID', 'Beneficiary Account Name', 'Beneficiary BIC', 'Beneficiary Intermediary BIC', 'Beneficiary Account Number', 'Credit Date', 'Credit Description', 'MISYS ID Instructed', 'MISYS ID Reconciled'];

export const DOCUMENT_MAPPING_TABLE_HEADER = ["Document Name", "ID", "Category", "File Required", "Upload Type"];

//Columns for User Comments
export const USER_COMMENTS_DEFINITION = [
    {
        id: 'commentDate',
        header: 'Comment Date',
        cell: item => item.commentDate,
        minWidth: 180
    },
    {
        id: 'comment',
        cell: item => item.comment,
        header: 'Comment',
        minWidth: 400,
        allowLineWrap: true
    },
    {
        id: 'user',
        header: 'User',
        cell: item => item.user,
        minWidth: 150,
        allowLineWrap: true
    }
];

//Columns for User Comments
export const PAYMENT_USER_COMMENTS_DEFINITION = [
    {
        id: 'CommentDate',
        header: 'Comment Date',
        cell: item => item ? item.CommentDate : '',
    },
    {
        id: 'Comment',
        cell: item => item ? item.Comment : '',
        header: 'Comment',
        allowLineWrap: true
    },
    {
        id: 'User',
        header: 'User',
        cell: item => item ? item.User : '',
        allowLineWrap: true
    }
];

//Columns for Potential Exception Warning.
export const POTENTIAL_EXCEPTIONS = [
    {
        id: 'tradeId',
        header: 'Trade ID',
        cell: item => item.tradeId,
        minWidth: 200
    },
    {
        id: 'inquiryDate',
        header: 'Inquiry Date',
        cell: item => item.inquiryDate,
        minWidth: 200,
        allowLineWrap: true
    },
    {
        id: 'exceptionOwner',
        header: 'Exception Owner',
        cell: item => item.exceptionOwner,
        minWidth: 300,
        allowLineWrap: true
    },
    {
        id: 'exceptionCategory',
        header: 'Exception Category',
        cell: item => item.exceptionCategory,
        minWidth: 300,
        allowLineWrap: true
    },
    {
        id: 'exceptionSubcategory',
        header: 'Exception Subcategory',
        cell: item => item.exceptionSubcategory,
        minWidth: 300,
        allowLineWrap: true
    }
];

export const DOCUMENT_DATA = [
    {
        id: 'Id',
        header: 'ID',
        cell: item => item.id,
        minWidth: 150
    },
    {
        id: 'documentID',
        header: 'Document ID',
        cell: item => item.documentId,
        minWidth: 200,
        allowLineWrap: true
    },
    {
        id: 'documentName',
        header: 'Document Name',
        cell: item => item.documentName,
        minWidth: 300,
        allowLineWrap: true
    },
    {
        id: 'uploadID',
        header: 'Upload ID',
        cell: item => item.uploadId,
        minWidth: 200,
        allowLineWrap: true
    },
    {
        id: 'status',
        header: 'Status',
        cell: item => item.status,
        width: 150,
        resizableColumns: true,
        allowLineWrap: true
    },
    {
        id: 'category',
        header: 'Category',
        cell: item => item.category,
        width: 150,
        resizableColumns: true,
        allowLineWrap: true
    },
    {
        id: 'uploader',
        header: 'Uploader',
        cell: item => item.uploader,
        width: 150,
        resizableColumns: true,
        allowLineWrap: true
    },
    {
        id: 'uploadType',
        header: 'Upload Type',
        cell: item => item.uploadType,
        width: 150,
        resizableColumns: true,
        allowLineWrap: true
    },
    {
        id: 'uploadTime',
        header: 'Upload Time',
        cell: item => item.uploadTime,
        minWidth: 200,
        allowLineWrap: true
    },
    {
        id: 'fileRequired',
        header: 'File Required',
        cell: item => item.fileRequired,
        maxWidth: 100,
        resizableColumns: true,
        allowLineWrap: true
    }
];

//Columns for Manual Export Dropdown Buttons
export const MANUAL_EXPORT_DROPDOWN_ITEMS = [
    {
      "text": "Download 360T File",
      "id": "download_360t",
      "disabled": false
    },
    {
      "text": "Download Bloomberg File",
      "id": "download_bloomberg",
      "disabled": false
    },
    {
      "text": "Download Manual File",
      "id": "download_manual",
      "disabled": false
    }
];

//Columns for Manual Export Dropdown Buttons
export const FILE_GENERATION_DROPDOWN_ITEMS = [
    {
      "text": "FX File",
      "id": "FX",
      "disabled": false
    },
    {
      "text": "Wire File",
      "id": "Wire",
      "disabled": false
    }
];

//Columns for Automated Export Dropdown Buttons
export const AUTO_EXPORT_DROPDOWN_ITEMS = [
    {
      "text": "Export Trade(s)",
      "id": "FX",
      "disabled": false
    },
    {
      "text": "Export Wire(s)",
      "id": "Wire",
      "disabled": false
    }
];

//Columns for Download Options in Pending Liquidity Checks
export const DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS = [
    {
      "text": "Download Selected",
      "id": "download_selected",
      "disabled": false
    },
    {
      "text": "Download All",
      "id": "download_all",
      "disabled": false
    }
];

//File type options for downloading items
export const DOWNLOAD_FILE_TYPE = [
    {
        "text": "XLSX",
        "id": "xlsx",
        "disabled": false
    },
    {
        "text": "CSV",
        "id": "csv",
        "disabled": false
    }
];

//Columns for Download Options in Pending Liquidity Checks
export const SINGLE_UPLOAD_FILE_CATEGORY_DROPDOWN_ITEMS = [
    {
        "label": "Invoice",
        "id": "Invoice",
        "value": "Invoice"
    },
    {
        "label": "Other",
        "id": "Other",
        "value": "Other"
    }
];
